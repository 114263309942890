import Api from '@/services/Index';

const login = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH;
    return Api.post('/v1/housing/tenants/login', user);
}
const passwordReset = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/housing/tenants/password/reset', formData);
}
const passwordResetVerify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    const form = {
        token: formData.token,
        sms_code: formData.sms
    };
    return Api.post('/housing/tenants/password/verify', form);
}
const passwordResetNewPassport = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    const form = {
        token: formData.token,
        password: formData.password,
        password_confirmation: formData.passwordConfirmation
    };

    return Api.post('/housing/tenants/password', form);
}



export default {
    login,
    passwordReset,
    passwordResetVerify,
    passwordResetNewPassport
}
