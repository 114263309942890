<template>
    <div>
        <ValidationObserver ref="loginForm">
            <b-form-group :label="$t('email')">
                <ValidationProvider name="email" rules="required|email" v-slot="{ valid, errors }">
                    <b-form-input
                        type="text"
                        v-model="form.email"
                        :state="errors[0] ? false : (valid ? true : null)">
                    </b-form-input>
                    <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                </ValidationProvider>
            </b-form-group>
            <b-form-group :label="$t('password')" class="input-icon position-relative">
                <ValidationProvider name="password" rules="required" v-slot="{ valid, errors }">
                    <b-form-input
                        :type="passwordVisible ? 'text' : 'password'"
                        v-model="form.password"
                        :state="errors[0] ? false : (valid ? true : null)">
                    </b-form-input>
                    <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                    <i @click="passwordVisible = !passwordVisible"
                       :class="passwordVisible ? 'ri-eye-2-line' : 'ri-eye-close-line'"
                       class="toggle-password-icon cursor-pointer"></i>
                </ValidationProvider>
            </b-form-group>
            <b-button @click="submitLogin" type="submit" variant="primary" class="btn-lg btn-block">
                {{ $t('login').toUpperCase() }}
            </b-button>
            <a class="small text-center d-block text-muted cursor-pointer mt-3" @click="changeActivePage('forgot-password')">
                {{ $t('forgot_my_password').toUpperCase() }}
            </a>
        </ValidationObserver>
    </div>
</template>

<script>
import HousingTenantLoginService from '@/services/HousingTenantLoginService'
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            passwordVisible: false,
        };
    },
    methods: {
        async submitLogin() {
            const isValid = await this.$refs.loginForm.validate();
            if (isValid) {
                try {
                    const response = await HousingTenantLoginService.login(this.form);
                    const token = response.data.data.token;
                    localStorage.removeItem('access_token');
                    localStorage.setItem('token', JSON.stringify(token));
                    localStorage.setItem('tenant_user',JSON.stringify(response.data.data));
                    const redirectPath = `/my-housing-reservations`
                    this.$router.push(redirectPath);

                } catch (error) {
                    this.handleLoginError(error);
                }
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        },
        handleLoginError(error) {
            if (error.status === 422) {
                // Handle validation errors
                if (error.data.errors.email) {
                    this.$refs.loginForm.errors.email.push(error.data.errors.email[0]);
                }
                if (error.data.errors.password) {
                    this.$refs.loginForm.errors.password.push(error.data.errors.password[0]);
                }
            } else {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        changeActivePage(page) {
            this.$store.commit('auth/setActivePage', page);
        },
    },
}
</script>

<style scoped>
.toggle-password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
</style>
