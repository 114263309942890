<template>
    <div class="p-2">
        <div class="fadeIn" v-show="accordion==1">
            <ValidationObserver ref="form1">

                <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                    <b-form-group :label="$t('email')">
                        <b-form-input type="text"
                                      v-model="form1.email"
                                      :state="errors[0] ? false : (valid ? true : null)">
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </ValidationObserver>
            <b-form-group>
                <b-button type="submit"
                          variant="primary"
                          @click="form1Submit()"
                          class="btn-lg btn-block d-flex justify-content-between align-items-center">
                    {{ $t('send_verification_code').toUpper() }}
                </b-button>
            </b-form-group>
        </div>
        <div class="fadeIn" v-show="accordion==2">
            <ValidationObserver ref="form2">
                <input type="text" v-model="form2.token" class="form-control d-none">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="col-form-label">{{ $t('verification_code') }}</label>
                    <div class="text-muted">
                        <i class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn
                    </div>
                </div>
                <ValidationProvider name="verificationCode" rules="required|length:6" v-slot="{valid, errors}">
                    <b-form-group>
                        <b-form-input type="text"
                                      v-model="form2.sms"
                                      class="d-none"
                                      :state="errors[0] ? false : (valid ? true : null)">
                        </b-form-input>
                        <div>
                            <otp-input
                                class="sms-input"
                                ref="otpInput"
                                inputClasses="form-control text-center font-size-1"
                                :numInputs="6"
                                separator=""
                                :shouldAutoFocus="false"
                                @on-complete="handleOnComplete"
                                @on-change="handleOnChange"
                            />
                        </div>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <div class="row">
                    <div class="col-6">
                        <b-form-group>
                            <b-button type="submit"
                                      variant="primary"
                                      class="btn-lg btn-block d-flex justify-content-between align-items-center"
                                      @click="form2Submit"
                                      :disabled="timerCount==0">
                                {{ $t('verify') }}
                            </b-button>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group>
                            <b-button type="submit"
                                      variant="outline-secondary"
                                      class="btn-lg btn-block d-flex justify-content-between align-items-center mt-0"
                                      @click="form1Submit"
                                      :disabled="timerCount!=0">
                                {{ $t('send_again').toUpper() }}
                            </b-button>
                        </b-form-group>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="fadeIn" v-show="accordion==3">
            <ValidationObserver ref="form3">
                <ValidationProvider name="password" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('new_password')"
                                  class="input-icon position-relative">
                        <b-form-input
                            :type="passwordStatus ? 'text':'password'"
                            v-model="form3.password"
                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                        <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordStatus=true"
                           v-if="!passwordStatus"></i>
                        <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordStatus=false"
                           v-if="passwordStatus"></i>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>

                <ValidationProvider name="passwordConfirmation" rules="required|confirmed:password"
                                    v-slot="{valid, errors}">
                    <b-form-group :label="$t('new_password_repeat')" class="input-icon position-relative">
                        <b-form-input
                            :type="passwordStatus ? 'text':'password'"
                            v-model="form3.passwordConfirmation"
                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                        <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordStatus=true"
                           v-if="!passwordStatus"></i>
                        <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordStatus=false"
                           v-if="passwordStatus"></i>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>

                <b-form-group>
                    <b-button type="submit"
                              variant="primary"
                              @click="form3Submit"
                              class="btn-lg btn-block d-flex justify-content-between align-items-center">
                        {{ $t('save').toUpper() }}
                    </b-button>
                </b-form-group>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import AuthService from "@/services/AuthService";
import OtpInput from "@bachdgvn/vue-otp-input";
import {EventBus} from "@/main";
import moment from "moment"
import HousingTenantLoginService from "@/services/HousingTenantLoginService";


export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        OtpInput
    },
    data() {
        return {
            accordion: 1,
            form1: {
                email: '',
            },
            form2: {
                token: null,
                sms: null,
            },
            form3: {
                token: null,
                password: null,
                passwordConfirmation: null,
            },
            passwordStatus: false,

            // Timer
            timerEnabled: false,
            timerCount: 0,
            masks: {
                input: 'MM/DD/YYYY',
            },
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleOnComplete(value) {
            this.form2.sms = value;
        },
        handleOnChange(value) {
            this.form2.sms = value
        },
        async form1Submit() {
            const isValid = await this.$refs.form1.validate();
            if (isValid) {
                let form={
                    email:this.form1.email
                }

                HousingTenantLoginService.passwordReset(form)
                    .then(response => {
                        const data = response.data.data;
                        this.form2.token = data.token;
                        this.timerCount = data.expires_in;
                        this.timerEnabled = true;
                        this.accordion = 2;
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.form1);
                        if (e.status == 422) {
                            if (e.data.errors.mobile_tel) {
                                this.$refs.form1.errors.mobileNumber.push(e.data.errors.mobile_tel[0]);
                            }
                            if (e.data.errors.email) {
                                this.$refs.form1.errors.email.push(e.data.errors.email[0]);
                            }
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        },
        async form2Submit() {
            const isValid = await this.$refs.form2.validate();
            if (isValid && this.timerCount > 0) {
                HousingTenantLoginService.passwordResetVerify(this.form2)
                    .then(response => {
                        const data = response.data.data;
                        this.form3.token = this.form2.token;
                        this.accordion = 3;
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.sms_code) {
                                this.$refs.form2.errors.verificationCode.push(error.data.errors.sms_code[0]);
                            }
                        } else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        },
        async form3Submit() {
            const isValid = await this.$refs.form3.validate();
            if (isValid) {
                HousingTenantLoginService.passwordResetNewPassport(this.form3)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.accordion = 1;
                        this.form1 = {
                            mobileNumber: null,
                            email: null,
                        };
                        this.$store.commit('auth/setActivePage', 'login');
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.password) {
                                this.$refs.form3.errors.password.push(error.data.errors.password[0]);
                            }
                        } else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        }
    },
};
</script>
